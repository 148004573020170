<template>
  <div v-if="!loadingData">
    <CampusHeader
        @start-creating="startCreatingCampus"
        @campus-selected="selectCampus"
        :campuses="campuses"
        :header-text="'Enrolments'"
        :can-create="false"
        @change-tab="changeTab"
        :activeTab="activeTab"
        :show-not-open-for-enrolment="false"
        :header_tabs="[{ title: 'Manage Enrolments' }]"
        :campus="active_campus"
    />
    <section class="section pt-4">
      <b-tabs expanded multiline  class="hidden-tabs" v-model="activeTab">
        <b-tab-item>
          <div v-if="!noCampuses" class="columns box">
            <div class="column is-4">
              <PhasesPanel
                  :canCreatePhase="false"
                  :canCreateStage="false"
                  :canEditStage="false"
                  :canDeletePhase="false"
                  :canDeleteStage="false"
                  :canEditPhase="false"
                  :canSelectPhase="true"
                  :showExtramurals="true"
                  :active-campus-id="activeCampusId"
                  :active_campus="active_campus"
                  :selected_phase="selected_phase"
                  :can-edit-level="false"
              />
            </div>
            <div class="column is-8">
              <EnrolmentsPanel
                  :canCreateSubject="
                  !!$store.getters['entities/user-permissions/find'](
                    'create subjects'
                  )
                "
                  :canEditSubject="false"
                  :canDeleteSubject="false"
                  :canCreateExtramural="
                  !!$store.getters['entities/user-permissions/find'](
                    'create extramurals'
                  )
                "
                  :canEditExtramural="false"
                  :canDeleteExtramural="false"
                  :canCreateEnrolment="
                  !!$store.getters['entities/user-permissions/find'](
                    'create enrolments'
                  )
                "
                  :canDeleteEnrolment="
                  !!$store.getters['entities/user-permissions/find'](
                    'delete enrolments'
                  )
                "
                  :canEditEnrolment="
                  !!$store.getters['entities/user-permissions/find'](
                    'edit enrolments'
                  )
                "
                  :active-campus-id="activeCampusId"
                  :campus="active_campus"
              />
            </div>
          </div>
        </b-tab-item>
      </b-tabs>
    </section>
  </div>
</template>

<script>
import Campus from "@/models/Campus";
import CampusHeader from "@/components/panelled-dash/CampusHeader";
import { mapState } from "vuex";
import CampusForm from "@/components/panelled-dash/CampusForm";
import PhasesPanel from "@/components/panelled-dash/PhasesPanel";
import EnrolmentsPanel from "@/components/panelled-dash/EnrolmentsPanel";

export default {
  name: "Campuses.dashboard",
  components: {
    EnrolmentsPanel,
    PhasesPanel,
    CampusHeader,
  },
  data() {
    return {
      loadingData: true,
      activeCampusId: null,
      noCampuses: false,
      activeTab: 0,
    };
  },
  methods: {
    deleteCampus(id) {
      this.loadingData = true;

      Campus.Delete(id)
        .then(() => {
          this.selectCampus(this.campuses[0].id);
          this.activeTab = 0;
          this.loadingData = false;
        })
        .catch((err) => {
          this.handleError(err)
          this.loadingData = false;
        });
    },

    startCreatingCampus() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          edit: false,
        },
        component: CampusForm,
        hasModalCard: true,
        trapFocus: true,
        events: {
          "campus-created": (id) => {
            this.selectCampus(id);
          },
        },
      });
    },
    selectCampus(id) {
      this.$store.dispatch("loader/show");
      this.$store.dispatch("campus/selectCampus", id);
      Campus.FetchById(id, ["phases"]).then(() => {
        this.loadingData = false;
        this.activeCampusId = id;
        this.$store.dispatch("loader/hide");
      });
    },

    changeTab(index) {
      this.activeTab = index;
    },
  },

  mounted() {
    this.$store.dispatch("loader/show");
    Campus.FetchAll({ page: 1, limit: 100 },{is_open_for_enrolment:1}).then((response) => {
      if (response.response.data.data.length > 0) {
        let campusId = parseInt(
          this.$store.state.campus.selected_campus_id !== null
            ? this.$store.state.campus.selected_campus_id
            : response.response.data.data[0].id
        );
        if (Campus.query().where('id',campusId).first().is_open_for_enrolment===0){
          campusId = Campus.query().where('is_open_for_enrolment',1).first().id
        }
        this.$store.dispatch("campus/selectCampus", campusId);
        Campus.FetchById(campusId, ["phases"]).then(() => {
          this.loadingData = false;
          this.activeCampusId = campusId;
          this.$store.dispatch("loader/hide");
        });
      } else {
        this.noCampuses = true;
        this.loadingData = false;
      }
    }).catch(
        err => {
          this.$store.dispatch("loader/hide");

          if (err.response.status === 422) {
            this.handleError(err)
          } else {
            this.handleError(err)
          }
        }
    )
  },
  computed: {
    ...mapState("campus", [
      "edit_stage",
      "selected_stage",
      "selected_phase",
      "filter_phase",
    ]),
    active_campus() {
      return Campus.query().whereId(this.activeCampusId).with("phases").first();
    },
    campuses() {
      return Campus.query().where('is_open_for_enrolment',1).orderBy("ordinality").get();
    },
  },
};
</script>

