<template>
  <div
      class="level has-background-info-light stage-container px-2 py-4 my-2"
  >

    <!-- Left side -->
    <div class="level-left">
      <div class="level-item">
        <p>
          {{ homeClass.name }}
        </p>
      </div>
    </div>

    <!-- Right side -->
    <div class="level-right">

      <b-tooltip
          label="Edit"
          size="is-small"
          v-if="canEditClass"
          type="is-warning"
      >
        <a

            class="card-header-icon has-text-black  px-0 py-0"
            @click.prevent.stop="startEdit(homeClass)"
        >
          <b-icon :icon="$tc('icons.edit')"
          ></b-icon>
        </a>
      </b-tooltip>

      <b-tooltip
          v-if="canDeleteClass"
          label="Delete"
          size="is-small"
          type="is-danger"
      >
        <a
            class="card-header-icon has-text-danger px-0 py-0"
            @click.prevent.stop="startDelete(homeClass.id)"
        >
          <b-icon :icon="$tc('icons.delete')"/>
        </a>

      </b-tooltip>

    </div>
  </div>

</template>

<script>

export default {
  name: "HomeClass",
  data() {
    return {}
  },

  props: {
    homeClass: {
      type: Object,
      required: true
    },

    canEditClass: {
      type: Boolean, default() {
        return false
      }
    },

    canDeleteClass: {
      type: Boolean, default() {
        return false
      }
    }

  },

  methods: {
    startEdit(homeClass) {
      this.$emit('edit-class', homeClass)
    },

    startDelete(homeClass_id) {
      this.$buefy.dialog.confirm({
        title: 'Deleting HomeClass',
        confirmText: 'Delete HomeClass',
        hasIcon: true,
        type: 'is-danger',
        message: 'Are you sure you want to delete this HomeClass?',
        onConfirm: () => this.$emit('delete-confirmed', homeClass_id)
      })
    },

  }
}
</script>

