<template>
  <form @submit.prevent="submit">
    <div :class="{'modal-card':inModal}" style="width: auto">
      <header v-if="inModal" class="modal-card-head">
        <p class="modal-card-title">{{ edit === false ? 'Create a new' : 'Edit' }} Campus</p>
      </header>
      <section :class="{'modal-card-body':inModal}">
        <b-field label="Name">
          <b-input
              v-model="campusObject.name"
              placeholder="Campus name"
              required>
          </b-input>
        </b-field>
        <b-field label="Open for Enrolment">
          <b-checkbox v-model="campusObject.is_open_for_enrolment" :false-value="0" :true-value="1">
            {{ campusObject.is_open_for_enrolment === 1 ? 'Yes' : 'No' }}
          </b-checkbox>
        </b-field>
        <b-field group-multiline grouped>
          <b-field expanded label="Building Number">
            <b-input
                v-model="campusObject.physical_address.line_2"
                :icon="$tc(`icons.map-marker`)"
                autocomplete="off"
                placeholder="10 Fancy Mansions"
            />
          </b-field>
          <b-field expanded label="Street Address">
            <b-input
                v-model="campusObject.physical_address.line_1"
                :icon="$tc(`icons.map-marker`)"
                autocomplete="off"
                placeholder="15 Main Street"
                required
            />
          </b-field>

        </b-field>
        <b-field group-multiline grouped>
          <b-field expanded label="City">
            <b-input
                v-model="campusObject.physical_address.city"
                :icon="$tc(`icons.map-marker`)"
                autocomplete="off"
                placeholder="Cape Town"
                required
            />
          </b-field>
          <b-field expanded label="Province">
            <b-input
                v-model="campusObject.physical_address.province"
                :icon="$tc(`icons.map-marker`)"
                autocomplete="off"
                placeholder="Western Cape"
                required
            />
          </b-field>
          <b-field expanded label="Postal Code">
            <b-input
                v-model="campusObject.physical_address.postal_code"
                :icon="$tc(`icons.map-marker`)"
                autocomplete="off"
                placeholder="5432"
                required
            />
          </b-field>
        </b-field>
        <b-field>
          <b-field label="Country">
            <b-input
                v-model="campusObject.physical_address.country"
                :icon="$tc(`icons.map-marker`)"
                autocomplete="off"
                placeholder="South Africa"
                required
            />
          </b-field>
        </b-field>
        <b-field v-if="edit">
          <b-field label="Contact Person">
            <UsersFilter :campus_id="campusObject.id" :selected_user_id="campusObject.head_user_id"
                         :type="'autocomplete'" :without_roles="['learner','guardian']"
                         @selected="selectUser"></UsersFilter>
          </b-field>
        </b-field>
        <b-field>
          <b-field label="New Application Notification Recipient Emails">
            <div class="table-container">
              <table class="table is-bordered is-striped">

                <tbody>
                <!--suppress JSUnusedLocalSymbols -->
                <tr v-for="(email,index) in application_recipient_emails" :key="index">
                  <td style="width: 400px">
                    <b-field expanded>
                      <b-input
                          v-model="application_recipient_emails[index]"
                          autocomplete="off"
                          expanded
                          name="email"
                          placeholder="Email address"
                          type="email"
                      />
                    </b-field>
                  </td>


                  <td>
                    <b-button
                        icon-right="delete"
                        type="is-ghost"
                        class="has-text-danger"
                        @click.prevent="removeEmail(index)"
                    />
                  </td>

                </tr>
                </tbody>
              </table>
              <b-field class="has-text-right" position="is-right">
                <b-button
                    type="is-primary"
                    @click.prevent="addEmail"
                >Add Email
                </b-button>
              </b-field>
            </div>
          </b-field>
        </b-field>
      </section>
      <section v-if="edit">
        <b-field label="Finance Task Users"></b-field>
        <NotificationsTable :campus_id="campus.id" :type_id="1"></NotificationsTable>
      </section>
      <section v-if="edit" class="mt-4">
        <b-field label="Covid Assessment Users"></b-field>
        <NotificationsTable :campus_id="campus.id" :show-activity-column="false" :type_id="2"></NotificationsTable>
      </section>
      <footer v-if="inModal" class="modal-card-foot">
        <button :icon-right="$tc(`icons.${edit===false?'create':'edit'}`)" :loading="loading" class="button"
                type="is-primary">Save
        </button>
      </footer>
      <b-field v-else class="mt-4" grouped>
        <div class="control">
          <b-button :loading="loading" native-type="submit"
                    tag="input"
                    type="is-primary" value="Save">
          </b-button>
        </div>
        <div v-if="edit && canDelete" class="control">
          <b-button :loading="loadingDelete" type="is-danger" @click.prevent="startDelete">
            Delete
          </b-button>
        </div>
      </b-field>
    </div>
  </form>
</template>

<script>
import Campus from "@/models/Campus";
import UsersFilter from "@/components/panelled-dash/UsersFilter";
import NotificationsTable from "@/components/user-notifications/NotificationsTable";

export default {
  name: "CampusForm",
  components: {UsersFilter, NotificationsTable},
  data() {
    return {
      application_recipient_emails: [''],
      loading: false,
      loadingDelete: false,
      campusObject: {
        is_open_for_enrolment: 1,
        application_emails: null,
        name: '',
        ordinality: 0,
        head_user_id: null,
        physical_address: {
          line_1: '',
          line_2: '',
          city: '',
          province: '',
          postal_code: '',
          country: ''
        }
      }
    }
  },
  methods: {
    addEmail() {
      this.application_recipient_emails.push('')
    },
    removeEmail(index) {
      this.application_recipient_emails.splice(index)
    },
    selectUser(user) {
      this.campusObject.head_user_id = user.id
    },
    startDelete() {
      this.$buefy.dialog.confirm({
        title: 'Deleting campus',
        confirmText: 'Delete Campus',
        hasIcon: true,
        type: 'is-danger',
        message: 'Are you sure you want to delete this campus?',
        onConfirm: () => this.delete()
      })
    },
    delete() {

      this.$emit('campus-deleted', this.campus.id)

    },
    submit() {
      if (this.application_recipient_emails[0] !== '') {
        this.campusObject.application_emails = this.application_recipient_emails.join(',')
      }
      if (this.edit) {
        if (this.canEdit) {
          this.loading = true
          Campus.Update(this.campusObject).then(() => {
            this.$buefy.snackbar.open(`Campus updated!`)
            this.$emit('close')
            this.loading = false
          }).catch(err => {
            this.handleError(err)
            this.loading = false

          })
        } else {
          this.$store.dispatch('toast/createToast')
        }
      } else {
        if (this.canCreate) {
          this.loading = true
          Campus.Store(this.campusObject).then(response => {
            this.$buefy.snackbar.open(`Campus created!`)
            this.$emit('campus-created', response.entities.campuses[0].id)
            this.$emit('close')
            this.loading = false
          }).catch(err => {
            this.handleError(err)
            this.loading = false
          })
        } else {
          this.$store.dispatch('toast/createToast')
        }
      }
    }
  },
  watch: {
    campus() {
      this.campusObject = this.campus
      if (this.campusObject.application_emails) {
        this.application_recipient_emails = this.campusObject.application_emails.split(',')
      } else if (this.campusObject.application_emails === null) {
        this.application_recipient_emails = []
      }
    }
  },
  mounted() {
    if (this.edit) {
      this.campusObject = this.campus
      if (this.campusObject.application_emails) {
        this.application_recipient_emails = this.campusObject.application_emails.split(',')
      } else if (this.campusObject.application_emails === null) {
        this.application_recipient_emails = []
      }
    }
  },
  props: {
    canDelete: {
      type: Boolean,
      default() {
        return false
      }
    }, canEdit: {
      type: Boolean, default() {
        return false
      }
    }, canCreate: {
      type: Boolean, default() {
        return false
      }
    },
    campus: {
      type: Object,
      default() {
        return {
          name: '', ordinality: 0
        }
      },
    },
    inModal: {
      type: Boolean, default() {
        return false
      }
    },
    edit: {
      type: Boolean, default() {
        return false
      }
    },
  }
}
</script>

