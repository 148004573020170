var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loadingData)?_c('div',[_c('CampusHeader',{attrs:{"campuses":_vm.campuses,"header-text":'Enrolments',"can-create":false,"activeTab":_vm.activeTab,"show-not-open-for-enrolment":false,"header_tabs":[{ title: 'Manage Enrolments' }],"campus":_vm.active_campus},on:{"start-creating":_vm.startCreatingCampus,"campus-selected":_vm.selectCampus,"change-tab":_vm.changeTab}}),_c('section',{staticClass:"section pt-4"},[_c('b-tabs',{staticClass:"hidden-tabs",attrs:{"expanded":"","multiline":""},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('b-tab-item',[(!_vm.noCampuses)?_c('div',{staticClass:"columns box"},[_c('div',{staticClass:"column is-4"},[_c('PhasesPanel',{attrs:{"canCreatePhase":false,"canCreateStage":false,"canEditStage":false,"canDeletePhase":false,"canDeleteStage":false,"canEditPhase":false,"canSelectPhase":true,"showExtramurals":true,"active-campus-id":_vm.activeCampusId,"active_campus":_vm.active_campus,"selected_phase":_vm.selected_phase,"can-edit-level":false}})],1),_c('div',{staticClass:"column is-8"},[_c('EnrolmentsPanel',{attrs:{"canCreateSubject":!!_vm.$store.getters['entities/user-permissions/find'](
                  'create subjects'
                ),"canEditSubject":false,"canDeleteSubject":false,"canCreateExtramural":!!_vm.$store.getters['entities/user-permissions/find'](
                  'create extramurals'
                ),"canEditExtramural":false,"canDeleteExtramural":false,"canCreateEnrolment":!!_vm.$store.getters['entities/user-permissions/find'](
                  'create enrolments'
                ),"canDeleteEnrolment":!!_vm.$store.getters['entities/user-permissions/find'](
                  'delete enrolments'
                ),"canEditEnrolment":!!_vm.$store.getters['entities/user-permissions/find'](
                  'edit enrolments'
                ),"active-campus-id":_vm.activeCampusId,"campus":_vm.active_campus}})],1)]):_vm._e()])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }