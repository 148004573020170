<template>
  <b-collapse
      class="card un-card"

      animation="slide"
      :open="isOpen === phase.id"
      @open="setOpen(phase.id)">
    <div
        @click.prevent.self
        slot="trigger"
        slot-scope="props"
        class="card-header has-background-grey-lighter"
    >

      <p class="card-header-title">

        <b-icon
            :icon="props.open ? 'menu-down' : 'menu-up'">
        </b-icon>
        {{ phase.learning_stream.code }} - {{ phase.name }}
      </p>
      <a class="card-header-icon is-flex is-align-items-center">

        <b-tooltip
            label="Select"
            size="is-small"
            position="is-left"

            v-if="canSelectPhase"
            type="is-success"
        >
          <b-icon type="is-black" @click.native.stop="selectPhase(true)"
                  :icon="isSelected?$tc('icons.selected'):$tc('icons.selectable')"
          ></b-icon>
        </b-tooltip>
        <b-tooltip
            v-if="canEditPhase"
            label="Edit"
            position="is-left"

            size="is-small"
            type="is-primary"
        >
          <a

              class="card-header-icon has-text-black  px-0 py-0"
              @click.prevent.stop="startEdit(phase)"
          >
            <b-icon :icon="$tc('icons.edit')"
            ></b-icon>
          </a>
        </b-tooltip>

        <b-tooltip
            v-if="canEditPhase"
            label="Move"
            position="is-left"

            size="is-small"
            type="is-primary"
        >
          <b-icon @click.native.stop type="is-primary" class="handle" :icon="$tc('icons.move')"
          ></b-icon>
        </b-tooltip>

        <b-tooltip
            v-if="canDeletePhase"
            label="Delete"
            position="is-left"

            size="is-small"
            type="is-primary"
        >
          <a
              class="card-header-icon has-text-danger px-0 py-0"
              @click.prevent.stop="startDelete(phase.id)"
          >
            <b-icon :icon="$tc('icons.delete')"/>
          </a>

        </b-tooltip>
      </a>
    </div>
    <div>
      <Stages @stage-selected="selectPhase(false)" :phase="phase" v-if="isOpen===phase.id"
              :phase_open="isOpen === phase.id"
              :phase_id="phase.id"
              @start-create-stage="startCreate(phase.id,phase.campus_id)"
              :showExtramurals="showExtramurals"
              :canCreateStage="canCreateStage"
              :canEditStage="canEditStage"
              :canDeleteStage="canDeleteStage"/>
    </div>
    <div v-if="showClasses">
      <HomeClasses :phase_open="isOpen === phase.id"
                   :phase_id="phase.id"
                   :canCreateClass="canCreateClass"
                   :canEditClass="canEditClass"
                   :canDeleteClass="canDeleteClass"/>
    </div>
  </b-collapse>

</template>

<script>


import Stages from "@/components/panelled-dash/Stages";
import HomeClasses from "@/components/panelled-dash/HomeClasses";

export default {
  name: "Phase",
  components: {Stages, HomeClasses},
  data() {
    return {}
  },

  props: {
    showClasses: {
      type: Boolean, default() {
        return false
      }
    },
    showExtramurals: {
      type: Boolean, default() {
        return true
      }
    },
    canCreateStage: {
      type: Boolean, default() {
        return false
      }
    },
    canEditStage: {
      type: Boolean, default() {
        return false
      }
    },
    canCreateClass: {
      type: Boolean, default() {
        return false
      }
    },
    canEditClass: {
      type: Boolean, default() {
        return false
      }
    },
    canDeleteClass: {
      type: Boolean, default() {
        return false
      }
    },
    canDeletePhase: {
      type: Boolean, default() {
        return false
      }
    },
    canDeleteStage: {
      type: Boolean, default() {
        return false
      }
    },
    canEditPhase: {
      type: Boolean, default() {
        return false
      }
    }, canSelectPhase: {
      type: Boolean, default() {
        return false
      }
    },
    phase: {
      type: Object,
      required: true
    },
    isOpen: {
      type: Number, required: true
    },
    isSelected: {
      type: Boolean, required: true
    }
  },
  methods: {
    selectPhase(fromSelf) {
      if (!this.isSelected) {
        this.$emit('select-phase', this.phase)
        if (fromSelf) {
          this.$emit('clear-stage-select')
        }
      }
    },
    startEdit(phase) {
      this.$emit('start-edit-phase', phase)
    },
    startCreate(phase_id, campus_id) {
      this.$emit('start-create-stage', {phase_id: phase_id, campus_id: campus_id,default_year:this.phase.year})
    },
    startDelete(phase_id) {
      this.$buefy.dialog.confirm({
        title: 'Deleting phase',
        confirmText: 'Delete Phase',
        hasIcon: true,
        type: 'is-primary',
        message: 'Are you sure you want to delete this phase?',
        onConfirm: () => this.$emit('delete-confirmed', phase_id)
      })
    }, setOpen(id) {
      this.$emit('opened', id)
    },
  }
}
</script>
