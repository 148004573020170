<template>

  <draggable
      class="py-4 px-4"
      animation=500
      handle=".handle"
      tag="div"
      v-model="sortedStages"
      :group="'stages'+phase_id"
      :force-fallback="true"
      :disabled="!canEditStage"
  >

    <!--    <transition-group name="list">-->
    <StageComponent @edit-stage="editStage" @stage-selected="stageSelected" @delete-confirmed="deleteStage"
                    v-for="stage in sortedStages"
                    :key="stage.id" :stage="stage"
                    :canEditStage="canEditStage"
                    :showExtramurals="showExtramurals"

                    :canDeleteStage="canDeleteStage"/>

    <div v-if="canCreateStage" slot="footer" @click="startCreateStage()"
         class="has-pointer level has-background-grey-light stage-container px-3 py-2 mt-2"
    >

      <!-- Left side -->
      <div class="level-left">
        <div class="level-item">
          <b-icon :icon="$tc('icons.create')" size="is-medium"/>

          <p>

            New Stage
          </p>
        </div>

      </div>
      <div style="width: 50%" class="level-right">
        <div style="width: 100%" class="level-item">
          <b-skeleton :animated="false" width="100%"></b-skeleton>
        </div>
      </div>
      <!-- Right side -->

    </div>

    <!--    </transition-group>-->
    <b-loading :is-full-page="false" v-model="loading" :can-cancel="false"></b-loading>
  </draggable>

</template>

<script>
import draggable from 'vuedraggable'
import StageComponent from "@/components/panelled-dash/Stage";
import Stage from "@/models/Stage";
import StageForm from "@/components/panelled-dash/StageForm";

export default {
  components: {draggable, StageComponent},
  name: "Stages",
  data() {
    return {
      loading: false,
      loaded: false
    }
  },
  methods: {
    startCreateStage() {
      this.$emit('start-create-stage')
    },
    editStage(stage) {
      this.$buefy.modal.open({
        parent: this,
        props: {
          stageObject: stage,
          edit: true,
        },
        component: StageForm,
        hasModalCard: true,
        trapFocus: true
      })
    },
    stageSelected(stage) {
      this.$store.dispatch('campus/selectStage', stage)
      this.$store.dispatch('campus/selectUser', null)
      this.$store.dispatch('campus/toggle_subject_select')
      this.$store.dispatch('campus/selectEnrolment', null)
      this.$emit('stage-selected')
    },
    deleteStage(id) {
      Stage.Delete(id).then(() => {
        this.$buefy.snackbar.open(`Stage deleted!`)
      })
    }
  },
  computed: {
    stages() {
      return Stage.query().where('phase_id', this.phase_id).where('campus_id', this.$store.state.campus.selected_campus_id).with('subjects').with('extramurals').orderBy('ordinality').get()

    },
    sortedStages: {
      get() {
        return this.stages
      },
      set(evt) {
        evt.map((currentelement, index) => {
          if (currentelement.ordinality !== index) {
            currentelement.ordinality = index;
            Stage.update(currentelement)
            Stage.Update({
              id: currentelement.id,
              ordinality: currentelement.ordinality,
              name: currentelement.name,
              campus_id: currentelement.campus_id,
              phase_id: currentelement.phase_id
            }, false)
          }
        });
      },
    }
  },
  props: {

    showExtramurals: {
      type: Boolean, default() {
        return true
      }
    },
    canEditStage: {
      type: Boolean, default() {
        return false
      }
    },
    canCreateStage: {
      type: Boolean, default() {
        return false
      }
    },
    canDeleteStage: {
      type: Boolean, default() {
        return false
      }
    },
    phase_id: {
      type: Number,
      required: true
    }, phase_open: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  watch: {
    // phase_open(newValue) {
    // if (newValue === true) {
    //   if (this.loaded === false) {
    //     this.loading = true
    //   }
    //   Stage.FetchAll({page: 1, limit: 999}, {
    //     phase_id: this.phase_id,
    //     campus_id: this.$store.state.campus.selected_campus_id
    //   }).then(() => {
    //     this.loading = false
    //     this.loaded = true
    //   })
    // }
    // }
  },
  mounted() {
    if (this.phase_open) {
      if (this.loaded === false) {
        this.loading = true
      }
      Stage.FetchAll({page: 1, limit: 999}, {
        phase_id: this.phase_id, campus_id: this.$store.state.campus.selected_campus_id
      }).then(() => {
        this.loading = false
        this.loaded = true
      })
    }
  }

}
</script>
