<template>
  <form @submit.prevent="submit">
    <div :class="{'modal-card':inModal}" style="width: auto">
      <header v-if="inModal" class="modal-card-head">
        <p class="modal-card-title">{{ edit === false ? 'Create a new' : 'Edit' }} HomeClass</p>
      </header>
      <section :class="{'modal-card-body':inModal}">
        <b-field label="Year">
          <b-select expanded required v-model="classObject.year" placeholder="Year">
            <option
                v-for="year of $store.getters['dates/years']"
                :key="year + 'year'"
                :value="year"
            >{{ year }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Name">
          <b-input
              v-model="classObject.name"
              placeholder="Class name"
              required>
          </b-input>
        </b-field>
        <b-field :label="$tc('Teacher',2)">
          <PerfectScrollbarWrapper>
          <b-table
              class="margin-top is-fullwidth"
              :data="classObject.teachers"
              :striped="true"
              :hoverable="true"
              :bordered="true"
          >
            <template #empty>
              <div class="has-text-centered">No {{ $tc('Teacher', 2) }}</div>
            </template>
            <b-table-column
                v-slot="props"
                sortable
                field="first_name"
                label="First Name"
            >{{ props.row.first_name }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                sortable
                field="last_name"
                label="Last Name"
            >{{ props.row.last_name }}
            </b-table-column>
            <b-table-column v-slot="props" sortable field="email" label="Email">{{
                props.row.email
              }}
            </b-table-column>
            <b-table-column v-slot="props" narrowed centered custom-key="actions">
              <b-field grouped>
                <b-field>
                  <b-button @click="viewUser(props.row)" type="is-primary"
                  >View
                  </b-button
                  >
                </b-field>
                <b-field>
                  <b-tooltip label="Remove" size="is-small" type="is-danger">
                    <b-button
                        type="is-danger"
                        :icon-right="$tc('icons.minus')"
                        @click="removeTeacher(props.row)"
                    ></b-button>
                  </b-tooltip>
                </b-field>
              </b-field>
            </b-table-column>
          </b-table>
          </PerfectScrollbarWrapper>
        </b-field>
        <b-field :label="`Add ${$tc('Teacher')}`">
          <UsersFilter :without_roles="['learner','guardian']" :dropdown-direction="'top'"
                       :type="'autocomplete'" @selected="selectUser"
                       :campus_id="classObject.campus_id"></UsersFilter>
        </b-field>
      </section>
      <footer :class="{'modal-card-foot':inModal}">
        <button class="button" type="is-primary" :icon-right="$tc(`icons.${edit===false?'create':'edit'}`)">Save
        </button>
      </footer>
    </div>
  </form>
</template>

<script>
import HomeClass from "@/models/HomeClass";
import UserProfile from "@/components/users/UserProfile";
import UsersFilter from "@/components/panelled-dash/UsersFilter";
import PerfectScrollbarWrapper from "@/components/scrollbar/PerfectScrollbarWrapper";

export default {
  name: "HomeClassForm",
  components: {PerfectScrollbarWrapper, UsersFilter},
  data() {
    return {
      classObject: {
        name: '',
        campus_id: this.campus_id,
        phase_id: this.phase_id,
        teachers: [],
        teachers_ids: [],
        year:this.$store.state.dates.year
      }
    }
  },
  methods: {
    viewUser(user) {
      this.$buefy.modal.open({
        parent: this,
        props: {
          canCreateWard: !!this.$store.getters['entities/user-permissions/find']('edit guardians'),
          canDeleteWard: !!this.$store.getters['entities/user-permissions/find']('edit guardians'),
          canEditWard: !!this.$store.getters['entities/user-permissions/find']('edit guardians')
          ,
          inModal: true,
          tabs: [
            {title: "User Details", role: "details"},
          ],
          proppedUser: user,
          canEdit: !!this.$store.getters["entities/user-permissions/find"](
              "edit users"
          ),
          canDelete: !!this.$store.getters["entities/user-permissions/find"](
              "delete users"
          ),
        },
        component: UserProfile,
        fullScreen: true,
        trapFocus: true,
      })
    }
    ,
    removeTeacher(user) {
      this.classObject.teachers = this.classObject.teachers.filter(teacher => teacher.id !== user.id)
      this.classObject.teachers_ids = this.classObject.teachers_ids.filter(teacher_id => teacher_id !== user.id)
    },
    selectUser(user) {
      this.classObject.teachers.push(user)
      this.classObject.teachers_ids.push(user.id)
    },
    submit() {

      if (this.edit) {
        HomeClass.Update({
          id: this.classObject.id,
          name: this.classObject.name,
          phase_id: this.phase_id,
          campus_id: this.campus_id,
          teachers: this.classObject.teachers_ids,
          year:this.classObject.year
        }, true).then(() => {
          HomeClass.FetchById(this.classObject.id, ['teachers']).then(() => {
            this.$buefy.snackbar.open(`HomeClass updated!`)
            this.$emit('close')
          })

        }).catch(err => {
          this.handleError(err)
        })
      } else {
        HomeClass.Store({
          id: this.classObject.id,
          name: this.classObject.name,
          phase_id: this.phase_id,
          campus_id: this.campus_id,
          teachers: this.classObject.teachers_ids,
          year:this.classObject.year
        }).then(() => {
          this.$buefy.snackbar.open(`HomeClass created!`)
          this.$emit('close')
        }).catch(err => {
          this.handleError(err)
        })
      }
    }
  }
  ,
  mounted() {
    if (this.edit) {
      this.classObject = this.homeClass
    }
    if (this.inModal) {
      this.makeModalLarge('animation-content')

    }
  }
  ,
  props: {
    campus_id: {
      type: Number,
      default
          () {
        return 0
      }
    }
    ,
    phase_id: {
      type: Number,
      default
          () {
        return null
      }
    }
    ,
    homeClass:
        {
          type: Object,
          default
              () {
            return {
              name: ''
            }
          }
          ,
        }
    ,
    inModal: {
      type: Boolean,
      default
          () {
        return false
      }
    }
    ,
    edit: {
      type: Boolean,
      default
          () {
        return false
      }
    }
    ,
  }
}
</script>

