<template>
  <form @submit.prevent="submit">
    <div :class="{'modal-card':inModal}" style="width: auto">
      <header v-if="inModal" class="modal-card-head">
        <p class="modal-card-title">{{ edit === false ? 'Create a new' : 'Edit' }} Phase</p>
      </header>
      <section :class="{'modal-card-body':inModal}">
        <b-field label="Year">
          <b-select expanded required v-model="phaseObject.year" placeholder="Year">
            <option
                v-for="year of $store.getters['dates/years']"
                :key="year + 'year'"
                :value="year"
            >{{ year }}
            </option>
          </b-select>

        </b-field>
        <b-field label="Name">
          <b-input
              v-model="phaseObject.name"
              placeholder="Phase name"
              required>
          </b-input>
        </b-field>
        <b-field label="Long Name">
          <b-input
              v-model="phaseObject.long_name"
              placeholder="Name for display"
              required>
          </b-input>
        </b-field>
        <b-field label="Head of Department">
          <b-select placeholder="Head of Department" v-model="phaseObject.hod_id">
            <option :value="null">None</option>
            <option v-for="user in Users" :value="user.id" :key="user.id">{{ user.first_name }} {{ user.last_name }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Deputy Head">
          <b-select placeholder="Deputy Head" v-model="phaseObject.sub_head_id">
            <option :value="null">None</option>
            <option v-for="user in Users" :value="user.id" :key="user.id">{{ user.first_name }} {{ user.last_name }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Level">
          <b-select expanded placeholder="Select a Level" required v-model="phaseObject.level_id">
            <option v-for="level in Levels" :key="level.id" :value="level.id">{{ level.name }}</option>
          </b-select>
        </b-field>

        <b-field type="is-primary" label="Learning Stream">
          <b-select expanded v-model="phaseObject.learning_stream_id" required class="has-text-primary"
                    placeholder="Select a Learning Stream">
            <option
                v-for="stream in LearningStreams"
                :value="stream.id"
                :key="stream.id">
              {{ stream.code }} | {{ stream.name }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Is open for enrolment">
          <b-switch :true-value="1" :false-value="0" v-model="phaseObject.is_open_for_enrolment">
            {{ phaseObject.is_open_for_enrolment === 1 ? 'Open' : 'Closed' }}
          </b-switch>
        </b-field>
      </section>
      <footer :class="{'modal-card-foot':inModal}">
        <button class="button" type="is-primary" :icon-right="$tc(`icons.${edit===false?'create':'edit'}`)">Save
        </button>
      </footer>
    </div>
  </form>
</template>

<script>
import Phase from "@/models/Phase";
import LearningStream from "@/models/LearningStream";
import User from "@/models/User";
import Level from "@/models/Level";
export default {
  name: "PhaseForm",
  data() {
    return {
      phaseObject: {
        name: '',
        ordinality: 0,
        campus_id: this.campus_id,
        learning_stream_id: null,
        is_open_for_enrolment: false,
        long_name: '',
        year: this.$store.state.dates.year,
        hod_id: null,
        sub_head_id: null,
        level_id: this.level_id
      }
    }
  },
  computed: {
    Users() {
      return User.query().get()
    },
    LearningStreams() {
      return LearningStream.query().all()
    },
    Levels() {
      return Level.query().orderBy('ordinality', 'asc').where('campus_id', this.phaseObject.campus_id).get()
    }
  },
  methods: {
    submit() {
      if (this.edit) {
        Phase.Update(this.phaseObject, true).then(() => {
          this.$buefy.snackbar.open(`Phase updated!`)
          this.$emit('close')
        }).catch(err => {
          this.handleError(err)
        })
      } else {
        Phase.Store(this.phaseObject).then(() => {
          this.$buefy.snackbar.open(`Phase created!`)
          this.$emit('close')
        }).catch(err => {
          this.handleError(err)
        })
      }
    }
  },
  mounted() {
    User.deleteAll()

    if (this.edit) {
      this.phaseObject = JSON.parse(JSON.stringify(this.phase))
    }
    User.FetchAll({page: 1, limit: 999}, {
      campus_id: this.phaseObject.campus_id,
      without_roles: ['learner', 'guardian']
    })
    Level.FetchAll({page: 1, limit: 999}, {campus_id: this.phaseObject.campus_id})
    LearningStream.FetchAll()
  },
  props: {
    campus_id: {
      type: Number, default() {
        return 0
      }
    }, level_id: {
      type: Number, default() {
        return null
      }
    },
    phase: {
      type: Object,
      default() {
        return {
          name: '', ordinality: 0
        }
      },
    },
    inModal: {
      type: Boolean, default() {
        return false
      }
    },
    edit: {
      type: Boolean, default() {
        return false
      }
    },
  }
}
</script>

