<template>
  <form @submit.prevent="submit">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ edit === false ? 'Create a new' : 'Edit' }} Stage</p>
      </header>
      <section class="modal-card-body">
        <b-field label="Year">
          <b-select expanded required v-model="stage.year" placeholder="Year">
            <option
                v-for="year of $store.getters['dates/years']"
                :key="year + 'year'"
                :value="year"
            >{{ year }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Name">
          <b-input
              v-model="stage.name"
              placeholder="Stage name"
              required>
          </b-input>
        </b-field>
        <b-field label="Long Name">
          <b-input
              v-model="stage.long_name"
              placeholder="Name for display"
              required>
          </b-input>
        </b-field>
        <b-field label="Is open for enrolment">
          <b-switch :true-value="1" :false-value="0" v-model="stage.is_open_for_enrolment">
            {{ stage.is_open_for_enrolment === 1 ? 'Open' : 'Closed' }}
          </b-switch>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <button class="button" type="is-primary" :icon-right="$tc(`icons.${edit===false?'create':'edit'}`)">Save
        </button>
      </footer>
    </div>
  </form>
</template>

<script>
import Stage from "@/models/Stage";

export default {
  name: "StageForm",
  data() {
    return {
      stage: {
        name: this.name,
        long_name: this.long_name,
        is_open_for_enrolment: this.is_open_for_enrolment,
        ordinality: 0,
        year:this.default_year,
        phase_id: this.phase_id,
        campus_id: this.campus_id
      }
    }
  },
  mounted(){
    if (this.edit) {
      this.stage = this.stageObject
    }
  },
  methods: {
    submit() {
      if (this.edit) {
        Stage.Update({
          name: this.stage.name,
          campus_id: this.stage.campus_id,
          phase_id: this.stage.phase_id,
          long_name: this.stage.long_name,
          is_open_for_enrolment: this.stage.is_open_for_enrolment,
          ordinality: this.stage.ordinality,
          year:this.stage.year,
          id: this.stage.id
        }, true).then(() => {
          this.$buefy.snackbar.open(`Stage updated!`)
          this.$emit('close')
        }).catch(err => {
          this.handleError(err)
        })
      } else {
        Stage.Store({
          name: this.stage.name,
          campus_id: this.stage.campus_id,
          phase_id: this.stage.phase_id,
          long_name: this.stage.long_name,
          is_open_for_enrolment: this.stage.is_open_for_enrolment,
          ordinality: this.stage.ordinality,
          year:this.stage.year,
        }).then(() => {
          this.$buefy.snackbar.open(`Stage created!`)
          this.$emit('close')
        }).catch(err => {
          this.handleError(err)
        })
      }
    }
  },
  props: {
    stageObject:{
      type:Object,
      default(){
        return null
      }
    },
    default_year:{
      type: Number,
      default() {
        return null
      }
    },
    is_open_for_enrolment: {
      type: [Boolean, Number], default() {
        return false
      }
    }, ordinality: {
      type: Number, required: false
    },
    name: {
      type: String, default() {
        return '';
      }
    }, long_name: {
      type: String, default() {
        return '';
      }
    },
    campus_id: {
      type: Number, required: false
    },
    phase_id: {
      type: Number, required: false
    },
    edit: {
      type: Boolean, default() {
        return false
      }
    },
    id: {
      type: Number, required: false
    }
  }
}
</script>

