<template>

  <draggable
      tag="section"
      class="pl-1 py-1"
      v-model="sortedPhases"
      group="phases"
      :disabled="!canEditPhase"
      :force-fallback="true"
      handle=".handle"
  >
    <transition-group name="list">
      <Phase @select-phase="selectPhase" @clear-stage-select="$store.dispatch('campus/clearSelectStage')"
             @start-create-stage="startCreateStage" @start-edit-phase="startEditPhase"
             :isOpen="isOpen"
             :show-classes="showClasses"
             :canCreateClass="canCreateClass"
             :canEditClass="canEditClass"
             :canDeleteClass="canDeleteClass"
             :canCreateStage="canCreateStage"
             :canEditStage="canEditStage"
             :canDeleteStage="canDeleteStage"
             :canEditPhase="canEditPhase"
             :canDeletePhase="canDeletePhase"
             :canSelectPhase="canSelectPhase"
             :showExtramurals="showExtramurals"
             :isSelected="selectedPhase?phase.id === selectedPhase.id:false"
             @delete-confirmed="deletePhase" @opened="openPhase"
             v-for="phase in sortedPhases"
             :phase="phase" :key="phase.id"/>
    </transition-group>
    <div v-if="canCreateClass" slot="footer" @click="$emit('start-create-phase')"
         class="has-pointer level has-background-grey-light stage-container px-3 py-2 mt-2"
    >

      <!-- Left side -->
      <div class="level-left">
        <div class="level-item">
          <b-icon :icon="$tc('icons.create')" size="is-medium"/>

          <p>

            New Phase
          </p>
        </div>

      </div>
      <div style="width: 50%" class="level-right">
        <div style="width: 100%" class="level-item">
          <b-skeleton :animated="false" width="100%"></b-skeleton>
        </div>
      </div>
      <!-- Right side -->

    </div>

  </draggable>

</template>

<script>
import draggable from 'vuedraggable'
import Phase from '@/components/panelled-dash/Phase'
import PhaseModel from '@/models/Phase'

export default {
  components: {draggable, Phase},
  name: "Phases",
  data() {
    return {
      isOpen: 0,
    }
  },

  methods: {
    selectPhase(phase) {
      this.$store.dispatch('campus/selectPhase', phase)
      this.$store.dispatch('campus/selectUser', null)
      this.$store.dispatch('campus/toggle_subject_select')
      this.$store.dispatch('campus/selectEnrolment', null)

    },
    startEditPhase(phase) {
      this.$emit('edit-phase', phase)
    },
    startCreateStage(data) {
      this.$emit('create-stage', data)
    },
    deletePhase(id) {
      PhaseModel.Delete(id).then(() => {
        this.$buefy.snackbar.open(`Phase deleted!`)
      })
    },
    openPhase(id) {
      this.isOpen = id
    }
  },
  computed: {
    phases() {
      return PhaseModel.query().where('year', this.$store.state.dates.year).with('learning_stream').where('level_id', this.level.id).orderBy('ordinality').withAllRecursive().get()
    },
    sortedPhases: {
      get() {
        return this.phases
      },
      set(evt) {
        evt.map((currentelement, index) => {
          if (currentelement.ordinality !== index) {
            currentelement.ordinality = index;
            this.$emit('order-changed', currentelement)
          }
        });
      },
    }
  },
  props: {
    level: {
      type: Object,
      required: true
    },
    canCreateClass: {
      type: Boolean, default() {
        return false
      }
    },
    canEditClass: {
      type: Boolean, default() {
        return false
      }

    },
    canDeleteClass: {
      type: Boolean, default() {
        return false
      }
    },
    showClasses: {
      type: Boolean,
      default() {
        return false
      }
    },
    canCreateStage: {
      type: Boolean, default() {
        return false
      }
    },
    canEditStage: {
      type: Boolean, default() {
        return false
      }
    },
    canDeletePhase: {
      type: Boolean, default() {
        return false
      }
    },
    canDeleteStage: {
      type: Boolean, default() {
        return false
      }
    },
    canEditPhase: {
      type: Boolean, default() {
        return false
      }
    }, canSelectPhase: {
      type: Boolean, default() {
        return false
      }
    }, showExtramurals: {
      type: Boolean, default() {
        return true
      }
    },
    selectedPhase: {
      type: Object, default() {
        return null
      }
    }
  },

}
</script>
