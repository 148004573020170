<template>
  <div @click="selectStage(stage.id)"
       class="has-pointer level has-background-info-light stage-container px-2 py-4 my-2"
       :class="{'has-background-success-light':stage.id===$store.state.campus.selected_stage_id}">

    <!-- Left side -->
    <div class="level-left">
      <div class="level-item">
        <p>
          {{ stage.name }}
        </p>
      </div>
      <div class="level-item">

        <b-tooltip
            label="Number of subjects"
            size="is-small"
            type="is-primary"
        >
          <p class="icon has-background-grey has-text-white box px-2 py-2">{{ stage.subjects.length }}</p>
        </b-tooltip>
      </div>
      <div class="level-item">

        <b-tooltip
            v-if="showExtramurals"
            label="Number of extramurals"
            size="is-small"
            type="is-primary"
        >
          <p class="icon has-background-link-light has-text-black box px-2 py-2">{{ stage.extramurals.length }}</p>
        </b-tooltip>
      </div>
    </div>

    <!-- Right side -->
    <div class="level-right is-flex is-align-items-center">

      <b-tooltip
          label="Edit"
          size="is-small"
          v-if="canEditStage"
          type="is-primary"
      >
        <a

            class="card-header-icon has-text-black  px-0 py-0"
            @click.prevent.stop="startEdit(stage)"
        >
          <b-icon :icon="$tc('icons.edit')"
          ></b-icon>
        </a>
      </b-tooltip>
      <b-tooltip
          label="Move"
          v-if="canEditStage"

          size="is-small"
          type="is-primary"
      >
        <b-icon type="is-primary" @click.native.stop class="handle" :icon="$tc('icons.move')"
        ></b-icon>
      </b-tooltip>
      <b-tooltip
          v-if="canDeleteStage"
          label="Delete"
          size="is-small"
          type="is-primary"
      >
        <a
            class="card-header-icon has-text-danger px-0 py-0"
            @click.prevent.stop="startDelete(stage.id)"
        >
          <b-icon :icon="$tc('icons.delete')"/>
        </a>

      </b-tooltip>

    </div>
  </div>

</template>

<script>
import Subject from "@/models/Subject";
import Extramural from "@/models/Extramural";

export default {
  name: "Stage",
  data() {
    return {}
  },

  props: {
    stage: {
      type: Object,
      required: true
    },

    canEditStage: {
      type: Boolean, default() {
        return false
      }
    },

    canDeleteStage: {
      type: Boolean, default() {
        return false
      }
    }, showExtramurals: {
      type: Boolean, default() {
        return true
      }
    }

  },

  mounted() {
    Subject.deleteAll()
    Subject.FetchAll({page: 1, limit: 999}, {
      stage_id: this.stage.id,
      campus_id: this.$store.state.campus.selected_campus_id,year:this.$store.state.dates.year
    },)
    if (this.showExtramurals) {
      Extramural.FetchAll({page: 1, limit: 999}, {
        stage_id: this.stage.id,
        campus_id: this.$store.state.campus.selected_campus_id,year:this.$store.state.dates.year
      })
    }
  },
  methods: {
    startEdit(stage) {
      this.$emit('edit-stage', stage)
    },
    selectStage() {
      this.$emit('stage-selected', this.stage)
    },
    startDelete(stage_id) {
      this.$buefy.dialog.confirm({
        title: 'Deleting stage',
        confirmText: 'Delete Stage',
        hasIcon: true,
        type: 'is-primary',
        message: 'Are you sure you want to delete this stage?',
        onConfirm: () => this.$emit('delete-confirmed', stage_id)
      })
    },

  }
}
</script>

