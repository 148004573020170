<template>
  <div>
    <b-table
        class="margin-top"
        :data="notifications"
        :loading="loading"
        :striped="true"
        :hoverable="true"
        :bordered="false"
    >
      <template #empty>
        <div class="has-text-centered">No notifications users</div>
      </template>
      <b-table-column
          v-slot="props"
          label="ID"
          field="id"
          sortable
          width="40"
          numeric
      >{{ props.row.id }}
      </b-table-column>
      <b-table-column
          v-slot="props"
          label="First Name"
          field="user.first_name"
          sortable

      >{{ props.row.user.first_name }}
      </b-table-column>
      <b-table-column
          v-slot="props"
          label="Last Name"
          field="user.last_name"
          sortable

      >{{ props.row.user.last_name }}
      </b-table-column>
      <b-table-column
          v-slot="props"
          label="Gets Email"
          field="gets_email"
          sortable

      >
        <b-checkbox v-model="props.row.gets_email"  :false-value="0" :true-value="1"></b-checkbox>
      </b-table-column>
      <b-table-column
          v-if="showActivityColumn"
          v-slot="props"
          label="Gets Activity"
          field="gets_task"
          sortable

      >
        <b-checkbox v-model="props.row.gets_task"  :false-value="0" :true-value="1"></b-checkbox>
      </b-table-column>
      <b-table-column
          v-slot="props"
          narrowed
          centered
          width="100"
          label="Actions"
          cell-class="py-1"
          custom-key="actions"
      >
        <b-field grouped>
          <b-field>
            <b-button @click="saveNotification(props.row)" type="is-primary"
            >Save
            </b-button
            >
          </b-field>
          <b-field>
            <b-button
                icon-right="delete"
                type="is-ghost"
                class="has-text-danger"                @click.prevent="removeNotification(props.row)"
            />
          </b-field>
        </b-field>
      </b-table-column>
    </b-table>
    <b-field :label="`Add user`">
      <UsersFilter :without_roles="['learner', 'guardian']" :dropdown-direction="'top'"
                   :type="'autocomplete'" @selected="addNotification"
                   :campus_id="campus_id"></UsersFilter>
    </b-field>
  </div>
</template>

<script>
import UsersFilter from "@/components/panelled-dash/UsersFilter";
import UserNotification from "@/models/UserNotification";

export default {
  name: "Term.index",
  components: {
    UsersFilter
  },
  data() {
    return {
      loading: false,
    };
  },
  props: {
    showActivityColumn:{
      type:Boolean,
          default(){
        return true
      }
    },
    campus_id: {
      type: Number, required: true
    }, type_id: {
      type: Number, default() {
        return null
      }
    },

  },
  watch:{
    campus_id(){
      this.loading = true
      UserNotification.FetchAll({page:1,limit:999},{campus_id:this.campus_id,type_id:this.type_id},['user']).then(()=>{
        this.loading = false
      }).catch(err=>{
        this.handleError(err)
        this.loading = false
      })
    }
  },
  methods: {
    saveNotification(notification){
      this.loading=true
      UserNotification.Update(notification).catch(err=>{
        this.handleError(err)
      }).finally(()=>{
        this.loading = false
      })
    },
    addNotification(user) {
      this.loading = true
      UserNotification.Store({
        type_id: this.type_id,
        campus_id: this.campus_id,
        gets_email: 1,
        gets_task: 0,
        user_id: user.id
      }).catch(err=>{
        this.handleError(err)
      }).finally(()=>{
        this.loading = false
      })
    },
    removeNotification(notification) {
      this.$buefy.dialog.confirm({
        title: `Removing User`,
        confirmText: `Remove User`,
        hasIcon: true,
        type: "is-danger",
        message: `Are you sure you want to remove this user??`,
        onConfirm: () =>
            UserNotification.Delete(notification.id)
                .then(() => {
                  this.loading = false
                  this.$buefy.snackbar.open(`User removed!`);
                })
                .catch((err) => {
                  this.loading = false
                  this.handleError(err)
                }),
      });
    },

  },

  computed: {
    notifications() {
      return UserNotification.query().where('campus_id', this.campus_id).where(notification => {
        return this.type_id === null ? true : notification.type_id === this.type_id
      }).with('user').get()
    }

  },
  mounted() {
    this.loading = true
    UserNotification.FetchAll({page:1,limit:999},{campus_id:this.campus_id,type_id:this.type_id},['user']).then(()=>{
      this.loading = false
    }).catch(err=>{
      this.handleError(err)
      this.loading = false
    })


  },
};
</script>
