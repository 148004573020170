<template>

  <div
      class="py-4 px-4"

  >

    <!--    <transition-group name="list">-->
    <HomeClassComponent @edit-class="editClass" @delete-confirmed="deleteClass"
                        v-for="homeClass in homeClasses"
                        :can-delete-class="canDeleteClass"
                        :key="homeClass.id" :home-class="homeClass"
                        :can-edit-class="canEditClass"
    />

    <div v-if="canCreateClass" slot="footer" @click="startCreateClass()"
         class="has-pointer level has-background-grey-light stage-container px-3 py-2 mt-2"
    >

      <!-- Left side -->
      <div class="level-left">
        <div class="level-item">
          <b-icon :icon="$tc('icons.create')" size="is-medium"/>

          <p>

            New HomeClass
          </p>
        </div>

      </div>
      <div style="width: 50%" class="level-right">
        <div style="width: 100%" class="level-item">
          <b-skeleton :animated="false" width="100%"></b-skeleton>
        </div>
      </div>
      <!-- Right side -->

    </div>

    <!--    </transition-group>-->
    <b-loading :is-full-page="false" v-model="loading" :can-cancel="false"></b-loading>
  </div>

</template>

<script>
import HomeClassComponent from "@/components/panelled-dash/HomeClass";
import HomeClassFrom from "@/components/panelled-dash/HomeClassForm";
import HomeClass from "@/models/HomeClass";

export default {
  components: {HomeClassComponent},
  name: "HomeClasses",
  data() {
    return {
      loading: false,
      loaded: false
    }
  },
  methods: {
    startCreateClass() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          phase_id: this.phase_id,
          campus_id: this.$store.state.campus.selected_campus_id,
          edit: false,
          inModal: true
        },
        component: HomeClassFrom,
        hasModalCard: true,
        trapFocus: true
      })

    },
    editClass(homeClass) {
      this.$buefy.modal.open({
        parent: this,
        props: {
          homeClass: homeClass,
          phase_id: homeClass.phase_id,
          campus_id: homeClass.campus_id,
          name: homeClass.name,
          edit: true,
          inModal: true
        },
        component: HomeClassFrom,
        hasModalCard: true,
        trapFocus: true
      })
    },

    deleteClass(id) {
      HomeClass.Delete(id).then(() => {
        this.$buefy.snackbar.open(`HomeClass deleted!`)
      })
    }
  },
  computed: {
    homeClasses() {
      return HomeClass.query().where('phase_id', this.phase_id).where('campus_id', this.$store.state.campus.selected_campus_id).with('teachers').orderBy('name').get()
    },
  },
  props: {
    showExtramurals: {
      type: Boolean, default() {
        return true
      }
    },
    canEditClass: {
      type: Boolean, default() {
        return false
      }
    },
    canCreateClass: {
      type: Boolean, default() {
        return false
      }
    },
    canDeleteClass: {
      type: Boolean, default() {
        return false
      }
    },
    phase_id: {
      type: Number,
      required: true
    }, phase_open: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  watch: {
    phase_open(newValue) {
      if (newValue === true) {
        if (this.loaded === false) {
          this.loading = true
        }
        HomeClass.FetchAll({page: 1, limit: 999}, {
          phase_id: this.phase_id,
          campus_id: this.$store.state.campus.selected_campus_id,year:this.$store.state.dates.year
        }, ['teachers']).then(() => {
          this.loading = false
          this.loaded = true
        })
      }
    }
  },
  mounted() {
    if (this.phase_open) {
      if (this.loaded === false) {
        this.loading = true
      }
      HomeClass.FetchAll({page: 1, limit: 999}, {
        phase_id: this.phase_id, campus_id: this.$store.state.campus.selected_campus_id,year:this.$store.state.dates.year
      }, ['teachers']).then(() => {
        this.loading = false
        this.loaded = true
      })
    }
  }

}
</script>
