<template>
  <form @submit.prevent="submit">
    <div :class="{'modal-card':inModal}" style="width: auto">
      <header v-if="inModal" class="modal-card-head">
        <p class="modal-card-title">{{ edit === false ? 'Create a new' : 'Edit' }} Level</p>
      </header>
      <section :class="{'modal-card-body':inModal}">

        <b-field label="Name">
          <b-input
              v-model="levelObject.name"
              placeholder="Level name"
              required>
          </b-input>
        </b-field>
        <b-field label="Long Name">
          <b-input
              v-model="levelObject.long_name"
              placeholder="Name for display"
              required>
          </b-input>
        </b-field>
        <b-field label="Head Role Name">
          <b-input v-model="levelObject.head_role_name" placeholder="Head"></b-input>
        </b-field>
        <b-field label="Head of Level">
          <b-select placeholder="Head of Level" v-model="levelObject.head_id">
            <option :value="null">None</option>
            <option v-for="user in Users" :value="user.id" :key="user.id">{{ user.first_name }} {{ user.last_name }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Deputy Head Role Name">
          <b-input v-model="levelObject.sub_head_role_name" placeholder="Sub head"></b-input>
        </b-field>
        <b-field label="Deputy Head">
          <b-select placeholder="Deputy Head" v-model="levelObject.sub_head_id">
            <option :value="null">None</option>
            <option v-for="user in Users" :value="user.id" :key="user.id">{{ user.first_name }} {{ user.last_name }}
            </option>
          </b-select>
        </b-field>
      </section>
      <footer :class="{'modal-card-foot':inModal}">
        <button class="button" type="is-primary" :icon-right="$tc(`icons.${edit===false?'create':'edit'}`)">Save
        </button>
      </footer>
    </div>
  </form>
</template>

<script>
import Level from "@/models/Level";
import User from "@/models/User";

export default {
  name: "LevelForm",
  data() {
    return {
      levelObject: {
        name: '',
        ordinality: 0,
        campus_id: this.campus_id,
        learning_stream_id: null,
        is_open_for_enrolment: false,
        long_name: '',
        year: this.$store.state.dates.year,
        head_id: null,
        sub_head_id: null,
        head_role_name: null,
        sub_head_role_name: null
      }
    }
  },
  computed: {
    Users() {
      return User.query().get()
    },

  },
  methods: {
    submit() {
      if (this.edit) {
        Level.Update(this.levelObject, true).then(() => {
          this.$buefy.snackbar.open(`Level updated!`)
          this.$emit('close')
        }).catch(err => {
          this.handleError(err)
        })
      } else {
        Level.Store(this.levelObject).then(() => {
          this.$buefy.snackbar.open(`Level created!`)
          this.$emit('close')
        }).catch(err => {
          this.handleError(err)
        })
      }
    }
  },
  mounted() {
    User.deleteAll()

    if (this.edit) {
      this.levelObject = this.level
    }
    User.FetchAll({page: 1, limit: 999}, {
      campus_id: this.levelObject.campus_id,
      without_roles: ['learner', 'guardian']
    })
  },
  props: {
    campus_id: {
      type: Number, default() {
        return 0
      }
    },
    level: {
      type: Object,
      default() {
        return {
          name: '', ordinality: 0
        }
      },
    },
    inModal: {
      type: Boolean, default() {
        return false
      }
    },
    edit: {
      type: Boolean, default() {
        return false
      }
    },
  }
}
</script>

